import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import type { Organization } from '~/repository/modules'

export const useOrganizationStore = defineStore('organization', () => {
  const { $api, $toast } = useNuxtApp()

  const organization = ref<Organization>()
  const loading = ref<boolean>(false)
  const errors = ref<AxiosError | null>(null)

  const get = async () => {
    errors.value = null
    loading.value = true

    try {
      const { data } = await $api.organization.get()
      organization.value = data
    }
    catch (e: unknown) {
      errors.value = e as AxiosError<unknown, any>
    }
    loading.value = false
  }

  $api.organization.on('updated', (_organization) => {
    $toast.addToast({
      title: 'Organization Updated!',
    })

    if (organization.value?.id === _organization.id)
      organization.value = _organization
  })

  return {
    organization,
    loading,
    errors,
    get,
  }
})
